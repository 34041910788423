<script setup lang="ts">
import { FormKitNode } from '@formkit/core'

const props = defineProps<{
  emailTo: string
}>()

const { form, showSuccessMessage, errors, onSubmit } = useEmailContactForm(props.emailTo)
</script>
<template>
  <div class="container form">
    <div v-if="showSuccessMessage" class="message-success">
      <h2>{{ $t('form.emailContactForm.success') }}</h2>
      <h2>{{ $t('form.emailContactForm.successMessage') }}</h2>
    </div>
    <FormKit
      v-else
      type="form"
      :actions="false"
      :errors="errors"
      @submit="onSubmit"
      @node="(n: FormKitNode) => (form = n)"
    >
      <h2>
        {{ $t('form.emailContactForm.modalTitle') }}
      </h2>
      <div class="split">
        <Input name="name" :label="$t('form.emailContactForm.name')" :required="true" />
        <Email name="email" :label="$t('form.emailContactForm.email')" :required="true" />
      </div>
      <Input name="subject" :label="$t('form.emailContactForm.subject')" :required="true" />
      <Textarea name="body" :label="$t('form.emailContactForm.body')" :required="true" />
      <Button primary class="submit-button">
        {{ $t('form.emailContactForm.submit') }}
      </Button>
    </FormKit>
  </div>
</template>
<style lang="scss" scoped>
.message-success {
  padding: 16px;
  color: var(--c-primary);
  text-align: center;
}
.formkit-form {
  display: grid;
  gap: var(--gap);

  &[data-loading] {
    opacity: 0.5;
    pointer-events: none;
  }
}
.split {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--gap);

  @include breakpoint(tl) {
    grid-template-columns: 1fr 1fr;
  }
}
.button {
  justify-self: end;
}
</style>
