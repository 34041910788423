import { reset, FormKitNode } from '@formkit/core'
import { useRecaptcha } from '~/api/drupal/useRecaptcha'

export const useEmailContactForm = (emailTo: string) => {
  const { t } = useI18n()
  const config = useRuntimeConfig()
  const { generateToken, verifyToken } = useRecaptcha()

  const urlFrom = ref(`${config.public.frontendBaseUrl}${useRoute().fullPath}`)

  const controller = ref<AbortController | null>(null)
  const errors = ref<string[]>([])
  const form = ref<FormKitNode | undefined>()
  const showSuccessMessage = ref(false)

  const onSubmit = async (formData: { name: string; email: string; subject: string; body: string }) => {
    if (!formData.email) {
      errors.value = [t('form.errors.generalError')]
      return
    }

    const token = await generateToken('emailContactFormRecaptcha')
    if (!token) {
      errors.value = [t('form.errors.generalError')]
      return
    }

    const recaptchaResult = await verifyToken(token)
    if (!recaptchaResult) {
      errors.value = [t('form.errors.generalError')]
      return
    }

    showSuccessMessage.value = false

    if (controller.value) {
      controller.value.abort()
    }

    const toRecipientData = {
      to: [emailTo],
      replyTo: formData.email,
      subject: `${t('form.emailContactForm.emailSubjectPrefix')} ${formData.subject}`,
      text: t('form.emailContactForm.recipientEmailBody', {
        sendersName: formData.name,
        sendersEmail: formData.email,
        message: formData.body,
        urlFrom: urlFrom.value,
      }),
    }

    const toSenderData = {
      to: [formData.email],
      replyTo: formData.email,
      subject: `${t('form.emailContactForm.emailSubjectPrefix')} ${formData.subject}`,
      text: t('form.emailContactForm.senderEmailBody', {
        sendersName: formData.name,
        sendersEmail: formData.email,
        message: formData.body,
      }),
    }

    controller.value = new AbortController()

    try {
      const [recipientResponse, senderResponse] = await Promise.all([
        $fetch(`${config.public.frontendBaseUrl}/api/sendgrid/send-mail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: toRecipientData,
          signal: controller.value.signal,
        }),
        $fetch(`${config.public.frontendBaseUrl}/api/sendgrid/send-mail`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: toSenderData,
          signal: controller.value.signal,
        }),
      ])

      if (!recipientResponse || !senderResponse) {
        errors.value = [t('form.errors.generalError')]
        return
      }
      showSuccessMessage.value = true
    } catch (error) {
      console.log(error)
    } finally {
      controller.value = null
    }
  }

  const onSuccessAcknowledgement = () => {
    showSuccessMessage.value = false
    reset(form.value || '')
  }

  return {
    form,
    showSuccessMessage,
    errors,
    onSubmit,
    onSuccessAcknowledgement,
  }
}
